.pricing-page-container {
    background-color: #000080; /* Navy blue */
    color: #ffffff; /* White text */
    padding: 40px;
    text-align: center;
  }
  
  .pricing-title {
    margin-bottom: 20px;
  }
  
  .credits-info {
    margin-bottom: 40px;
  }
  
  .pricing-options {
    justify-content: center;
  }
  
  .pricing-column {
    display: flex;
    justify-content: center;
  }
  
  .pricing-card {
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Card shadow */
    border-radius: 10px; /* Rounded corners */
  }
  
  .pricing-card.popular {
    border: 2px solid #2196f3; /* Blue border for popular card */
  }
  
  .popular-badge {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #2196f3; /* Blue background for badge */
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 10px;
    font-weight: bold;
  }
  
  .price {
    color: #2196f3; /* Blue price text */
  }
  
  .pay-button {
    margin-top: 20px;
    background-color: #2196f3; /* Blue button */
    border: none;
  }
  
  /* You may need to adjust the colors to match your exact palette */
  