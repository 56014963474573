
.columnStatus{
    display: "flex";
    align-items: center;
    justify-content: center;

}
.myprojectsProjectName{
    font-size:18px;
    font-weight: bolder;
    padding: 0;
   
    margin: 0;
}
.dot-step-indicator {
    display: flex;
    justify-content: space-evenly;
 
    

  }
  


  
  .dot-step {
    height: 30px;
    width: 30px;
    
    background-color: #bbb;
    border-radius: 50%;
    position: relative;
    z-index: 1;
  }
  
  .dot-step.active {
    height: 50px;
    width: 50px;
    background-color: #1890ff; /* Active step color */
  }
  
@media screen and (max-width: 768px) {
  .noneOnMobile{
    display: none;
  }
    .myprojectsProjectName{
        font-size:14px;

        font-weight: bolder;

   
    }
}
@media screen and (max-width: 992px) {
  .columnStatus{
      display: none;
  }
 
}