html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color:rgb(18, 19, 23) /* Set the background color to black */
    /*background-color:#cecece;*/
  }
  /* Mobile view */
.navbar-mobile {
  display: none;
}

/* Desktop view */
.navbar-desktop {
  display: flex;
  align-items: center;
  height: 100%;

}


.ant-progress-bg{
  background-color: #21B3CD !important;
}

.header {
    backdrop-filter: blur(20px); /* Apply blur effect */
    -webkit-backdrop-filter: blur(20px); /* For Safari browser support */
    background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
    width: 100%; /* Full width */
    padding: 15px; /* Padding for content */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Optional: adds a shadow for depth */
    position: fixed; /* Optional: makes the header stay at the top */
    top: 0; /* Position at the top */
    left: 0; /* Align left */
    z-index: 10; /* Ensure it's above other content */
}

/*
.ant-divider-horizontal.ant-divider-with-text-left::before,
.ant-divider-horizontal.ant-divider-with-text-left::after
{
   border-top: 1px solid #d5dddd;
}
*/
.ant-layout .ant-layout-sider-trigger{
  bottom:  auto !important;
  top:60px;
  width: 60px;
  left: 0;
  background-color: #272832;
}
.ant-btn:hover {
  background-color: aliceblue;
  color: #272832 !important;
  border-color: #272832 !important;

}

.ant-btn-default:hover {
  background-color: white;
  color: #272832 !important;
  border-color: #272832 !important;
}

.hide-desktop{
  display:none;
}
.hide-mobile{
  display: block;
}

.card-home{
  width: 100%;
  flex-direction: column;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 5%;
}
.image-home{
  max-width: 550px;
  padding:0px ;
  max-height: 300px;
  width: 100%;
  background-size: contain;
}
.image-home-squared{
  max-width: 300px;
  padding:0px ;
  max-height: 300px;
  width: 100%;
  background-size: contain;
}
.image-home-smaller{
  max-width: 400px;
  padding:30px ;
  width: 100%;
  max-height: 380px;
  background-size: contain;
}
.home-container{
  padding-top: 100px;
  padding-left:10%;
  padding-right:10%;
  padding-bottom: 100px;
}
.subtitle{
  width:100%;

  font-size: 20px;
  margin-top: 20px;
  max-width: 600px;
  text-align:center;

}


.custom-message {
  color: white ; /* Optional: if you want to change the text color */
}
.ant-message-notice-content{
  background-color:#121317 !important;
  color: white;
}
/* Small screen / mobile view */
@media screen and (max-width: 768px) {
  .subtitle{
    padding-left: 10%;
    padding-right: 10%;
  }
  .home-container{
    padding:20px;
    padding-top:80px;

  }
  .hide-mobile{
    display:none;
  }
  .hide-desktop{
    display:block;
  }
  .image-home{
    max-width: 400px;
    padding: 50px;
    padding-bottom: 20px;
  }
  .image-home-smaller{
    padding: 50px;
  }

  .card-home{
    width: 100%;
    flex-direction: column;
    display: flex;
    align-content: center;
    align-items: center;
    padding: 0px;
  }
  .navbar-desktop {
    display: none;
  }
  .navbar-mobile {
    display: inline-block;

  }
  .sider{
    display: none;
  }

  .ant-layout .ant-layout-sider-trigger{
    bottom:  auto !important;
    top:60px;
    width: 60px;
    left: 0;
  }
 
}


.ant-modal-content{
 background-color:#171a1c !important;
}