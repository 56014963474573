
.user-credits {
    display: flex;
    align-items: center;
   /* Adjust the color to match your design */
    color: white;
    padding: 10px 20px;
    border-radius: 20px;
    font-family: 'Arial', sans-serif; /* Use the font of your choice */
  }
  
  .user-info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.7); /* slightly larger */
    }
    100% {
      transform: scale(1);
    }
  }
  
  .animate-credit {
    animation: pulse 600ms ease-in-out;
    color:red;
    /* Other styles for the animated text */
  }
  
  
  
  .user-name {
    max-width: 100px; /* Add a unit like 'px' */
    white-space: nowrap; /* Prevent text from wrapping to the next line */
    overflow: hidden; /* Hide text that doesn't fit in the max-width */
    text-overflow: ellipsis; /* Add '...' at the end of the truncated text */
    font-size: 14px; /* Adjust size as needed */
  }
  
  .credits {
    font-size: 12px; /* Adjust size as needed */
  }
  