.thumbnail-card {
    position: relative;
    cursor: pointer;
  }
  .sample-canvas{
    height: 800px;
  }
  
  .thumbnail-card img {
    border-radius: 10px;
  }
  
  .thumbnail-card .video-length {
    position: absolute;
    bottom: 8px;
    right: 8px;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 2px 4px;
    border-radius: 2px;
    font-size: 0.8rem;
  }
  
  .thumbnail-card .ant-card-meta-title {
    color: white;
    margin-bottom: 4px; /* Adjust as needed */
  }
  
  .thumbnail-card .ant-card-meta-description {
    color: white;
    font-size: 0.8rem;
  }
  