.font-select-container {
    display: flex;
    align-items: center;
    width:100%;
  }
  
  .font-select {

  }
  
  .arrow-buttons {
    display: flex;
    color:white;
    flex-direction: column;
  }
  .arrow-button {
    color:white;
    height:20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  @media screen and (max-width: 992px) {
    .arrow-button {
      color:white;
      height:30px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
     
    }
    
  }
  
  
  